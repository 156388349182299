// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientID: 'uJ0EHpmMway8YLOzUMfd6s1DsbSAjgJj',
  domain: 'dev-auth.cargosignal.dev',
  redirect_uri: 'https://dev-m.cargosignal.dev/callback',
  authScope: 'openid profile email',
  audience: 'https://dev-api.cargosignal.com',
  appUrlPrefix: 'https://dev-m.cargosignal.dev',
  whitelistedDomains: ['localhost:4200', 'cargosignal.dev', 'dev-m.cargosignal.dev'],
  mapboxApiKey: 'pk.eyJ1IjoiY2FyZ29zaWduYWwiLCJhIjoiY2tkdWZ1dzdsMXB6bzM5bGpybXgyMjNhMSJ9.TUl0GIqUrvlxiubuTqU92g',
  mapboxChinaApiKey: 'pk.eyJ1IjoiY2FyZ29zaWduYWwiLCJhIjoiY2tkdWZ1dzdsMXB6bzM5bGpybXgyMjNhMSJ9.TUl0GIqUrvlxiubuTqU92g',
  featureFlags: {
    messaging: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
